import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { debounce } from 'lodash';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { UserService } from '../../../services/user.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-g2g-btn-follow',
  standalone: true,
  imports: [FormsModule, ToggleButtonModule],
  templateUrl: './g2g-btn-follow.component.html',
  styleUrl: './g2g-btn-follow.component.scss'
})
export class G2gBtnFollowComponent implements OnChanges, OnDestroy {
  @Input('playerId') playerId: string | undefined;
  @Input('isFollowed') isFollowed: boolean = false;
  originalFollowState: boolean = false;
  private $unsubscribe = new Subject<void>();
  @Output('actionComplete') actionComplete: EventEmitter<boolean> = new EventEmitter<boolean>(false);


  constructor(
    private _userService: UserService,
    private _authService: AuthService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.originalFollowState = JSON.parse(JSON.stringify(this.isFollowed));
    console.log(this.playerId);
  }

  interact() {
    // this.isFollowed = !this.isFollowed;
    this.performAction();
  }

  performAction = debounce(() => {
    if (this.isFollowed != this.originalFollowState) {
      console.log("API CALL WILL BE MADE");
      if (this.playerId) {
        // LIKE POST API CALL
        if (this.isFollowed) {
          this.followUser(this.playerId);
        } else {
          this.unfollowUser(this.playerId);
        }
      }
    }
  }, 500);

  followUser(playerId: string) {
    this._authService.showLoader.next(false);
    this._userService.followUser(playerId)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.originalFollowState = this.isFollowed;
        this.actionComplete.emit(this.isFollowed);
        this._authService.showLoader.next(true);
      }, (err) => {
        this._authService.showLoader.next(true);
        this.isFollowed = this.originalFollowState
      });
  }

  unfollowUser(playerId: string) {
    this._authService.showLoader.next(false);
    this._userService.unfollowUser(playerId)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((res) => {
        this.originalFollowState = this.isFollowed;
        this.actionComplete.emit(this.isFollowed);
        this._authService.showLoader.next(true);
      }, (err) => {
        this._authService.showLoader.next(true);
        this.isFollowed = this.originalFollowState
      });
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }
}
