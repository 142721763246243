import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiconstants } from '../constants/api.constant';
import { IResponse } from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private _http: HttpClient,
  ) { }

  followUser(userId: string): Observable<any> {
    let url = apiconstants.API_FOLLOW_USER.replace('{id}', userId);
    return this._http.post(url, null).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }


  unfollowUser(userId: string): Observable<any> {
    let url = apiconstants.API_UNFOLLOW_USER.replace('{id}', userId);
    return this._http.post(url, null).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getProfileByUserId(userId: string): Observable<any> {
    let url = apiconstants.API_GET_USER_PROFILE.replace('{id}', userId);
    return this._http.get(url).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }
  getAllUsers(filter: any): Observable<any> {
    let params = {
      filterBy: filter || 'All'
    }
    return this._http.get(apiconstants.API_GET_ALL_USERS, { params }).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  getAllNotifications(): Observable<any> {
    return this._http.get(apiconstants.API_GET_NOTIFICATIONS).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  clearAllNotifications(): Observable<any> {
    return this._http.delete(apiconstants.API_DELETE_NOTIFICATIONS).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  clearNotification(notificationId: string): Observable<any> {
    return this._http.delete(apiconstants.API_DELETE_NOTIFICATIONS + '/' + notificationId).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  searchUserByName(name: string): Observable<any> {
    let params = {
      name
    }
    return this._http.get(apiconstants.API_SEARCH_USER, { params }).pipe(
      map((response: IResponse) => {
        return response.data;
      })
    );
  }

  inviteForTrial(payload: any): Observable<any> {
    return this._http.post(apiconstants.API_INVITE, payload).pipe(
      map((response: IResponse) => {
        return response.message;
      })
    );
  }


}
